<template>
  <div>
    <h1>Thanks!</h1>
    <p>
      Thanks for your interest in {{tenant.name}}. We will get back to you ASAP.
    </p>
    <p>
      In the mean time, feel free to explore the features of our platform:
    </p>
    <p>
      <v-btn outlined color="primary" to="/features" class="mr-4">Features</v-btn>
      <v-btn outlined color="primary" to="/about">More about us</v-btn>
    </p>
  </div>
</template>


<script>
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "ThankYou",
  props: {
  },
  data: function() {
    return {
      tenant: tenant,
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style scoped>
</style>